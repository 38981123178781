<script setup lang="ts">
import type { FormInst } from 'naive-ui'
import { z } from 'zod'

useHead({ title: 'H-OS - Login' })
definePageMeta({
  layout: 'auth',
  auth: {
    unauthenticatedOnly: true,
    navigateAuthenticatedTo: '/',
  },
})

const { signIn } = useAuth()

const isLoading = ref(false)
function triggerSignIn() {
  isLoading.value = true
  return signIn('azure-ad')
}

const error = useRoute().query.error
if (error && error !== 'undefined') {
  const notification = useNotification()
  notification.info({ duration: 5000, description: `Login fehlgeschlagen - probieren Sie es erneut! (Fehlercode: ${error})` })
}

// E-Mail sign in
function triggerSignInEmail(email: string | null) {
  if (email === null) {
    return
  }
  isLoading.value = true
  return signIn('email', { email, callbackUrl: '/' })
}

const isEmailSignOnFlow = ref<false | 'started' | 'finished'>(false)
interface ModelType {
  email: string | null
}
const formRef = ref<FormInst | null>(null)
const formData = ref<ModelType>({
  email: null,
})

const mailSchema = z.string().email()
const isValidEmail = (email: string | null) => mailSchema.safeParse(email).success

const query = useRoute().query
if (query.callbackUrl?.includes('verify-request')) {
  isEmailSignOnFlow.value = 'finished'
}
</script>

<template>
  <div v-if="isEmailSignOnFlow === false">
    <p class="text-base">
      Bitte wählen Sie eine der unten vorgeschlagenen Möglichkeiten um sich anzumelden.
    </p>

    <n-divider class="!my-6" />

    <div class="flex flex-col gap-4">
      <n-button-group size="large" class="flex">
        <n-button class="shrink-0" :disabled="isLoading" @click="triggerSignIn">
          <template #icon>
            <TheLogo :display-small="true" class="text-base" />
          </template>
        </n-button>
        <n-button type="primary" class="grow" :loading="isLoading" @click="triggerSignIn">
          <span class="font-medium">
            <template v-if="isLoading">
              Anmeldung läuft...
            </template>
            <p v-else class="flex items-center gap-1">
              <span class="hidden md:block">
                Mit
              </span>
              <span>
                {{ $t('app_name') }}-Account
              </span>
              <span class="hidden md:block">
                anmelden
              </span>
            </p>
          </span>
        </n-button>
      </n-button-group>

      <n-button size="large" type="primary" secondary class="grow" @click="isEmailSignOnFlow = 'started'">
        <span class="font-medium">
          Mit E-Mail anmelden
        </span>
      </n-button>
    </div>
  </div>

  <n-form v-else-if="isEmailSignOnFlow === 'started'" ref="formRef" :model="formData" :rules="{ email: { required: true } }" class="flex flex-col gap-2">
    <div class="flex flex-col gap-3">
      <p class="text-base">
        Bitte geben Sie Ihre E-Mail Adresse ein um sich anzumelden:
      </p>

      <n-input
        v-model:value="formData.email"
        type="text"
        placeholder="E-Mail eingeben"
        autofocus
        :disabled="isLoading"
        size="large"
      />
      <n-button
        attr-type="submit"
        type="primary"
        size="large"
        block
        :loading="isLoading"
        :disabled="isLoading || !isValidEmail(formData.email)"

        @click="triggerSignInEmail(formData.email)"
      >
        {{ isLoading ? 'Anmeldung läuft...' : 'Anmelden' }}
      </n-button>
      <n-button size="large" secondary block @click="isEmailSignOnFlow = false">
        <template #icon>
          <Icon name="material-symbols:chevron-left" />
        </template>
        Zurück
      </n-button>
    </div>
  </n-form>
  <div v-else-if="isEmailSignOnFlow === 'finished'">
    <div class="mb-6">
      <h1 class="text-xl mb-4 font-medium">
        Schauen Sie in Ihr Postfach!
      </h1>
      <p class="text-base">
        Eine Mail wurde an Ihre Mail-Adresse versendet. Bitte klicken Sie auf den dort enthaltenen Link um sich anzumelden.
      </p>
    </div>
    <n-button size="large" secondary block @click="isEmailSignOnFlow = false">
      <template #icon>
        <Icon name="material-symbols:chevron-left" />
      </template>
      Zurück
    </n-button>
  </div>
</template>
